import React from 'react';
import { useLocation } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './articleDetail.css'; // Custom styles
import UsePageSEO from '../../hooks/UsePageSEO/index';

const ArticleDetail = () => {
  const location = useLocation();
  const { title, description, imgs } = location.state;

  // Set SEO for the page
  UsePageSEO({
    title,
    description,
  });

  return (
    <div className="article-detail-container">
      <h1>{title}</h1>
      {/* Render the description with images */}
      <div dangerouslySetInnerHTML={{ __html: description }} />
      <Carousel>
        {imgs && imgs.map((img, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={`https://api.goldenpharaohtours.com/uploads/${img}`}
              alt={`Image ${index + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default ArticleDetail;
