import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './article.css'; // Custom styles
import axios from 'axios';
import UsePageSEO from '../../hooks/UsePageSEO/index';
import { useNavigate } from 'react-router-dom';

const Article = () => {
  const navigate = useNavigate();

  UsePageSEO({
    title: "Blogs",
    description: "Explore our latest blogs covering a variety of topics, from lifestyle and technology to health and travel. Stay updated with insightful articles, tips, and trends that inspire and inform.",
  });

  const [articles, setArticles] = useState({
    loading: true,
    results: [],
    err: null,
    reload: "0"
  });

  useEffect(() => {
    setArticles(prevState => ({ ...prevState, loading: true }));
    axios.get("https://api.g/getallarticals")
      .then(resp => {
        setArticles(prevState => ({
          ...prevState,
          results: resp.data,
          loading: false,
          err: null
        }));
      })
      .catch(() => {
        setArticles(prevState => ({
          ...prevState,
          loading: false,
          results: [],
          err: "Failed to fetch articles"
        }));
      });
  }, [articles.reload]);

  const handleCardClick = (article) => {
    const { id, imgs, title, descreption } = article;
    navigate(`/ArticleDetail/${id}`, { state: { title, description: descreption, imgs } });
  };

  return (
    <div className="site-section" data-aos="fade-up">
      {articles.loading ? (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner-container">
            <div className="loading-spinner">&#9765;</div>
            <span>Loading...</span>
          </div>
        </div>
      ) : (
        <Container className="article-container">
          <Row className="justify-content-center">
            {articles.results.map((article, index) => (
              <Col key={index} xs={12} sm={6} md={4} className="d-flex justify-content-center mb-4">
                <div onClick={() => handleCardClick(article)} style={{ cursor: 'pointer', width: '100%' }}>
                  <Card className="article-card">
                    <div className="meta">
                      {article.imgs && article.imgs.length > 0 && (
                        <Card.Img
                          variant="top"
                          src={`https://api.goldenpharaohtours.com/uploads/${article.imgs[0]}`}
                          alt={`Image 1`}
                          className="img-fluid"
                        />
                      )}
                      <br /><br /><br /><br /><br />
                      <Card.Title>{article.title}</Card.Title>
                    </div>
                    <div className="content">
                      <Card.Body>
                        <Card.Text>
                          {/* Render HTML in description */}
                          <div dangerouslySetInnerHTML={{ __html: article.descreption }} />
                        </Card.Text>
                      </Card.Body>
                    </div>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </div>
  );
};

export default Article;
