import React, { useState, useEffect } from "react";
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import 'react-clock/dist/Clock.css';
import "../../Assets/css/spinner.css";
import { getAuthUser } from '../../Helper/Storage';
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import './CreatePost.css';
import Spinner from 'react-bootstrap/Spinner';
const Auth = getAuthUser();

const UpdateTrip = () => {
    let { id: tripId } = useParams(); // Get tripId from URL
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [selectedDestination, setSelectedDestination] = useState("Select Country");
    const [tripInclusions, setTripInclusions] = useState([]);
    const [tripExclusions, setTripExclusions] = useState([]);
    const [offerName, setOfferName] = useState("");
    const [offerDescription, setOfferDescription] = useState("");
    const [itinerary, setItinerary] = useState([]);
    const [destinations, setDestinations] = useState([]);

    const navigate = useNavigate();

    const [Post, setPost] = useState({
        name: "",
        description: "",
        salarySingle: "",
        salaryDouble: "",
        salaryGroup: "",
        category: "",
        destination: "",
        duration: "",
        country: "",
        type: "",
        other: "none",
        err: []
    });

    useEffect(() => {
        const fetchDestinations = async () => {
            try {
                const response = await axios.get('https://api.goldenpharaohtours.com/getdestinations');
                setDestinations(response.data);
            } catch (error) {
                console.error("Failed to fetch destinations", error);
            }
        };

        fetchDestinations();
    }, []);

    useEffect(() => {
        if (tripId) {
            const fetchTripDetails = async () => {
                try {
                    const response = await axios.get(`https://api.goldenpharaohtours.com/gettripdetails/${tripId}`);
                    const data = response.data;

                    const itineraryData = data.itinerary || [];
                    setPost({
                        name: data.name,
                        description: data.description,
                        salarySingle: data.price1,
                        salaryDouble: data.price2,
                        salaryGroup: data.pricee,
                        category: data.category,
                        destination: data.destination,
                        duration: data.Duration,
                        country: data.Countries,
                        other: data.Other,
                        err: []
                    });
                    setTripInclusions(data.included.split(","));
                    setTripExclusions(data.excluded.split(","));
                    setOfferName(data.offerName);
                    setOfferDescription(data.offerDes);
                    setItinerary(itineraryData);
                    setSelectedCategory(data.category);
                    setSelectedDestination(data.destination);
                    setPreview(data.images || []);
                } catch (error) {
                    console.error(error);
                    setPost(prev => ({ ...prev, err: [error.response?.data?.msg || "Failed to fetch data"] }));
                }
            };

            fetchTripDetails();
        }
    }, [tripId]);

    const Do_Post = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", Post.name);
        formData.append("description", Post.description);
        formData.append("price1", Post.salarySingle);
        formData.append("price2", Post.salaryDouble);
        formData.append("pricee", Post.salaryGroup);
        formData.append("category", selectedCategory);
        formData.append("destination", selectedDestination);
        formData.append("included", tripInclusions.join(","));
        formData.append("excluded", tripExclusions.join(","));
        formData.append("offerName", offerName);
        formData.append("offerDes", offerDescription);
        formData.append("Duration", Post.duration);
        formData.append("Countries", Post.country);
        formData.append("Run", "Weekly");
        formData.append("Itinerary", JSON.stringify(itinerary));

        for (let i = 0; i < images.length; i++) {
            formData.append("images", images[i]);
        }

        setLoading(true);
        try {
            const response = await axios.put(`https://api.goldenpharaohtours.com/updatetrip/${tripId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            navigate("/");
            setPost({ ...Post, loading: false, err: [] });
        } catch (error) {
            console.log(error);
            setPost({ ...Post, loading: false, err: [error.response?.data?.msg || "Update failed"] });
        } finally {
            setLoading(false);
        }
    };

    const addInclusion = () => setTripInclusions([...tripInclusions, ""]);
    const removeInclusion = (index) => setTripInclusions(tripInclusions.filter((_, i) => i !== index));
    const handleInclusionsChange = (index, e) => setTripInclusions(tripInclusions.map((inclusion, i) => (i === index ? e.target.value : inclusion)));

    const addExclusion = () => setTripExclusions([...tripExclusions, ""]);
    const removeExclusion = (index) => setTripExclusions(tripExclusions.filter((_, i) => i !== index));
    const handleExclusionsChange = (index, e) => setTripExclusions(tripExclusions.map((exclusion, i) => (i === index ? e.target.value : exclusion)));

    const handleImageChange = (event) => {
        const files = event.target.files;
        setImages(files);

        const imagesPreview = [];
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = () => {
                imagesPreview.push(reader.result);
                if (imagesPreview.length === files.length) {
                    setPreview(imagesPreview);
                }
            };
        }
    };

    const handleResetClick = () => {
        setPreview([]);
        setImages([]);
    };

    const handleItineraryChange = (index, field, value) => {
        const newItinerary = [...itinerary];
        newItinerary[index][field] = value;
        setItinerary(newItinerary);
    };

    const addItineraryDay = () => {
        setItinerary([...itinerary, { day: "", details: "" }]);
    };

    const removeItineraryDay = (index) => {
        const newItinerary = itinerary.filter((_, i) => i !== index);
        setItinerary(newItinerary);
    };

    return (
        <div className="create-post-container">
            <Card className="create-post-card">
                <Card.Body>
                    <br /><br />
                    <h1>Update Trip</h1>
                    {Post.err.map((error, index) => (
                        <Alert key={index} variant='danger'>
                            {error}
                        </Alert>
                    ))}
                    <Form onSubmit={Do_Post} className="post-form">
                        <Form.Group className="trip-destination-group">
                            <Form.Label>Trip Destination</Form.Label>
                            <Form.Control
                                type="text"
                                value={Post.name}
                                onChange={(e) => setPost({ ...Post, name: e.target.value })}
                                placeholder="Enter trip destination"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Trip Details</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={Post.description}
                                onChange={(e) => setPost({ ...Post, description: e.target.value })}
                                rows={5}
                                placeholder="Write trip details here..."
                                required
                            />
                        </Form.Group>

                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Category</Form.Label>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            {selectedCategory}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setSelectedCategory("All")}>All</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("FullDay")}>Full Day</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("HalfDay")}>Half Day</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("Nighttours")}>Night tours</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("Packages")}>Packages</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("SpecialOffers")}>Special Offers</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("VIP")}>VIP</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Destination</Form.Label>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            {selectedDestination}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {destinations.map((destination, index) => (
                                                <Dropdown.Item key={index} onClick={() => setSelectedDestination(destination)}>
                                                    {destination}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Label>Trip Inclusions</Form.Label>
                            {tripInclusions.map((inclusion, index) => (
                                <div key={index} className="inclusion-item">
                                    <Form.Control
                                        type="text"
                                        value={inclusion}
                                        onChange={(e) => handleInclusionsChange(index, e)}
                                        placeholder="Inclusion"
                                    />
                                    <Button variant="danger" onClick={() => removeInclusion(index)}>Remove</Button>
                                </div>
                            ))}
                            <Button variant="success" onClick={addInclusion}>Add Inclusion</Button>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Trip Exclusions</Form.Label>
                            {tripExclusions.map((exclusion, index) => (
                                <div key={index} className="exclusion-item">
                                    <Form.Control
                                        type="text"
                                        value={exclusion}
                                        onChange={(e) => handleExclusionsChange(index, e)}
                                        placeholder="Exclusion"
                                    />
                                    <Button variant="danger" onClick={() => removeExclusion(index)}>Remove</Button>
                                </div>
                            ))}
                            <Button variant="success" onClick={addExclusion}>Add Exclusion</Button>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Trip Itinerary</Form.Label>
                            {itinerary.map((item, index) => (
                                <div key={index} className="itinerary-item">
                                    <Form.Control
                                        type="text"
                                        value={item.day}
                                        onChange={(e) => handleItineraryChange(index, 'day', e.target.value)}
                                        placeholder={`Day ${index + 1}`}
                                        required
                                    />
                                    <Form.Control
                                        as="textarea"
                                        value={item.details}
                                        onChange={(e) => handleItineraryChange(index, 'details', e.target.value)}
                                        rows={3}
                                        placeholder={`Details for day ${index + 1}`}
                                        required
                                    />
                                    <Button variant="danger" onClick={() => removeItineraryDay(index)}>-</Button>
                                </div>
                            ))}
                            <Button variant="primary" onClick={addItineraryDay}>+ Add Itinerary Day</Button>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Upload Images</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                multiple
                                onChange={handleImageChange}
                            />
                        </Form.Group>

                        <div className="image-preview">
                            {preview.map((image, index) => (
                                <Image key={index} src={image} alt="Preview" thumbnail />
                            ))}
                            {preview.length > 0 && (
                                <Button variant="danger" onClick={handleResetClick}>
                                    Reset Images
                                </Button>
                            )}
                        </div>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    Updating...
                                </>
                            ) : "Update Trip"}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default UpdateTrip;
