import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../Assets/css/spinner.css';
import sphinx from "../../Images/sunrise.jpg";
import camel from "../../Images/camel.jpg";
import sand from "../../Images/sunrise2.jpg";
import image1 from "../../Images/sand.jpg";
import image2 from "../../Images/py.jpg";
import image3 from "../../Images/sphinx.jpg";
import pyramid from "../../Images/pyramid.mp4";
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Reviews from '../Reviews/Reviews';
import { useMediaQuery } from 'react-responsive';
import UsePageSEO from '../../hooks/UsePageSEO/index'

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

const Home = () => {
   UsePageSEO({
    title : "Best Egypt Tour Packages from USA | Golden Pharaoh’s Tours",
    description : "Join our best Egypt vacation packages from the USA. Enjoy unique experiences, guided tours, and comfortable accommodations. Start planning your journey",
  })
  const { t } = useTranslation();
  const [tripsState, setTopTrips] = useState({
    loading: true,
    results: [],
    err: null,
    reload: "0"
  });
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setTopTrips(prevState => ({ ...prevState, loading: true }));
    axios.get("https://api.goldenpharaohtours.com/toptrip", {})
      .then(resp => {
        setTopTrips(prevState => ({ ...prevState, results: resp.data, loading: false, err: null }));
      })
      .catch(() => {
        setTopTrips(prevState => ({ ...prevState, loading: false, results: [], err: null }));
      });
  }, [tripsState.reload]);

  useEffect(() => {
    const filteredResults = tripsState.results.filter(trip => trip.name.toLowerCase().includes(search.toLowerCase()));
    setFilteredTrips(filteredResults);
  }, [search, tripsState.results]);

  return (
    <div className="site-wrap">
      <div className="site-section">
        <div className="bg-video">
          <video autoPlay muted loop style={{ width: '100%', height: '400px', objectFit: 'cover' }}>
            <source src={pyramid} type="video/mp4" />
          </video>
          <Container className="text-center text-white py-5" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <h2 className="text-center text-white py-5" style={{ color: 'white' }}>{t('Our Destinations')}</h2>
            <p className="text-center text-white " style={{ color: 'white' }}>{t('Choose Your Next Destination')}</p>
            <Form>
              <Form.Group className="mb-3 d-flex justify-content-center" controlId="search">
                <Form.Control type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('Your Next Trip')} />
              </Form.Group>
            </Form>
          </Container>
        </div>

        <ScrollingCards t={t} filteredTrips={filteredTrips} tripsState={tripsState} />

        <FeatureCards t={t} />

        <PackageCategories t={t} />

        <HowItWorks t={t} />

        <Reviews limit={4} />
        
        <AboutSection t={t} />
      </div>
    </div>
  );
};
const FeatureCards = ({ t }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const flipCardStyle = {
    marginBottom: '1rem',
    width: isMobile ? '100%' : 'auto', // Full width on mobile, auto on larger screens
  };

  const textCardStyle = {
    marginBottom: '1rem',
    width: '100%',
  };

  const flipCardContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: isTabletOrMobile ? 'center' : 'flex-start',
  };

  const textCardContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: isTabletOrMobile ? 'center' : 'flex-end',
  };

  return (
    <Container className="my-5">
      <Row className="feature-cards-wrapper">
        <h1 style={{color:'orange'}}>{t('Why Choose Us?')}</h1>
        <Col md={6} >
          <div className="feature-card-flip" style={flipCardContainerStyle}>
            <Card
              className="feature-card text-white text-center bg-primary mb-3"
              style={{ ...flipCardStyle, backgroundImage: `url(${sphinx})` }}
            >
              <Card.Body>
                <Card.Title>{t('Why Choose Us?')}</Card.Title>
                <Card.Text>{t("Expert Guides: Our knowledgeable and friendly guides are passionate about the history and culture of Egypt. They are committed to providing informative and engaging tours that bring the past to life.")}</Card.Text>
              </Card.Body>
            </Card>
            <Card
              className="feature-card text-white text-center bg-primary mb-3 feature-card-back"
              style={{ ...flipCardStyle, backgroundImage: `url(${sand})` }}
            >
              <Card.Body>
                <Card.Title>{t('Personalized Service')}</Card.Title>
                <Card.Text>
                {t(" We pride ourselves on offering personalized service to every client. From the moment you book with us until your journey ends, we ensure that every detail is taken care of.")}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col md={6} className="order-1">
          <div className="text-card-container" style={textCardContainerStyle}>
            <Card className="feature-card text-center" style={textCardStyle}>
              <Card.Body>
                <Card.Title style={{color:'orange'}}>{t('Our Story')}</Card.Title>
                <Card.Text>
                  {t('Founded in 2024, golden pharaoh’s tours was born out of a passion for showcasing the splendor of Egypt to the world. With years of experience in the travel and tourism industry, our team of experts combines local knowledge with a global perspective to create tours that are both authentic and immersive.')}
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="feature-card text-center" style={textCardStyle}>
              <Card.Body>
                <Card.Title style={{color:'orange'}}>{t('What We Offer')}</Card.Title>
                <Card.Text>
                  {t('Historical Tours: Explore the ancient wonders of Egypt, from the majestic Pyramids of Giza and the Sphinx to the temples of Luxor and Karnak. Walk through the Valley of the Kings and discover the treasures of Tutankhamun.')}
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="feature-card text-center" style={textCardStyle}>
              <Card.Body>
                <Card.Title style={{color:'orange'}}>{t('Adventure Tours')}</Card.Title>
                <Card.Text>
                  {t('Dive into thrilling adventures with our desert safaris, Nile River cruises, and snorkeling or diving trips in the Red Sea.')}
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="feature-card text-center" style={textCardStyle}>
              <Card.Body>
                <Card.Title style={{color:'orange'}}>{t('Cultural Experiences')}</Card.Title>
                <Card.Text>
                  {t('Immerse yourself in the vibrant culture of Egypt. Enjoy traditional music and dance performances, visit bustling bazaars, and savor the flavors of Egyptian cuisine.')}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};



const AboutSection = ({ t }) => {
  const mitRahinaCoordinates = [29.8440, 31.2497];

  return (
    <Container>
      <Row className="my-5">
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h5 className="text-uppercase text-muted"  >{t('About Us')}</h5>
          <h1 className="display-4">{t('Discover the Wonders of Egypt with golden pharaoh’s tours')}</h1>
          <p>{t("Welcome to golden pharaoh’s tours, where your journey through the timeless beauty and rich history of Egypt begins. As a premier tourism company based in Egypt, we are dedicated to providing unforgettable travel experiences that cater to all types of travelers. Whether you're an adventurer, a history enthusiast, or someone looking to relax and unwind, we have the perfect itinerary for you.")}</p>
          <Link to={`/Tripscards`}><Button variant="warning">{t('Book Now')}</Button></Link>
        </Col>
        <Col md={6}>
          <MapContainer center={mitRahinaCoordinates} zoom={13} style={{ height: '100%', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={mitRahinaCoordinates}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer>
        </Col>
      </Row>
    </Container>
  );
};

const ScrollingCards = ({ t, filteredTrips, tripsState }) => {
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '300px', // Adjust height as necessary
    overflow: 'hidden',
    backgroundColor: '#f0f0f0', // Optional: background color for better visibility
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Ensures the image covers the container without distortion
  };
  const tripGroups = chunkArray(filteredTrips, 3);

  return (
    <Container className="my-5">
      <Row className="justify-content-center text-center mb-4">
        <Col lg={6}>
          <h2 className="font-weight-light" style={{color:'orange'}}>{t('Every Missing Moment')}</h2>
          <p>{t('Top Trips')}</p>
        </Col>
      </Row>
      {tripsState.loading ? (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner-container">
            <div className="loading-spinner">&#9765;</div>
            <span>Loading...</span>
          </div>
        </div>
      ) : filteredTrips.length > 0 ? (
        <Carousel>
          {tripGroups.map((group, index) => (
            <Carousel.Item key={index}>
              <Row className="justify-content-center">
                {group.map((trip, tripIndex) => (
                  <Col key={tripIndex} md={4}>
                    <div className="blog-card">
                    <div className="meta" style={containerStyle}>
                    <img
                      src={`https://api.goldenpharaohtours.com/uploads/${trip.img}`}
                      alt={`Trip ${tripIndex}`}
                      style={imageStyle}
                    />
                  </div>
                      <div className="description">
                        <h2>{trip.name}</h2>
                        <p>{truncateText(trip.description, 60)}</p> {/* Adjust 100 to the desired length */}
                        <Link to={`/TripsDetails/${trip.id}`}>
                        <Button className="description" variant="warning">Book NOW</Button></Link>
                        <div className="author">
                          <img src={trip.authorImage} alt={trip.authorName} />
                          <span className="name">{trip.authorName}</span>
                          <span className="date">{trip.date}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <Alert variant="danger">{t('No Trips Available, Try Again Later')}</Alert>
      )}
    </Container>
  );
};

const PackageCategories = ({ t }) => {
  const trips = [
    {
      id: 1,
      title: t('Adventure'),
      description: t('Embark on thrilling adventures with our desert safaris and more.'),
      img: image1, // Replace with your image path
    },
    {
      id: 2,
      title: t('Cultural'),
      description: t('Experience the rich culture of Egypt with our immersive cultural packages.'),
      img: image2 , // Replace with your image path
    },
    {
      id: 3,
      title: t('Historical'),
      description: t('Dive deep into history with our detailed and engaging historical tours.'),
      img: camel, // Replace with your image path
    }
  ];

  return (
    <div className="package-categories">
      <Container className="my-5">
        <Row className="justify-content-center text-center mb-4">
          <Col lg={6}>
            <h2 className="font-weight-light" style={{ color: 'orange' }}>{t('Package Categories')}</h2>
            <p>{t('Top Trips')}</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {trips.map((trip) => (
            <Col key={trip.id} md={4}>
              <div className="blog-card">
                <div className="meta">
                  <div
                    className="photo"
                    style={{ backgroundImage: `url(${trip.img})` }}
                  ></div>
                </div>
                <div className="description">
                  <h2>{trip.title}</h2>
                  <p>{truncateText(trip.description, 60)}</p> {/* Adjust 100 to the desired length */}
                  {/* <Link to={`/Tripscards?category=${trip.title}`}><Button variant="warning">{t('View Packages')}</Button></Link> */}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};




const HowItWorks = ({ t }) => {
  const steps = [
    { title: t("Step 1"), description: t("Choose Your Destination"), icon: "🌍" },
    { title: t("Step 2"), description: t("Book Your Trip"), icon: "📅" },
    { title: t("Step 3"), description: t("Pack Your Bags"), icon: "🧳" },
    { title: t("Step 4"), description: t("Enjoy Your Journey"), icon: "✈" }
  ];

  return (
    <Container className="how-it-works-section my-5">
      <h2 className="text-center mb-4" style={{color:'orange'}}>{t('How it Works')}</h2>
      {steps.map((step, index) => (
        <Row className="step mb-4" key={index}>
          <Col xs={2} className="text-center-warning">
            <div className="step-icon" style={{ fontSize: '2rem' }}>{step.icon}</div>
          </Col>
          <Col xs={10}>
            <h3 style={{color:'orange'}}>{step.title}</h3>
            <p>{step.description}</p>
          </Col>
        </Row>
      ))}
    </Container>
  );
};
// 2/8/2024

export default Home;
