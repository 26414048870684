import {createBrowserRouter,Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import TripsCards from "./pages/trips/Tripscards";
import Register from "./pages/Auth/Register";
import App from "./App";
import Login from "./pages/Auth/login";
import CreatePost from "./pages/Admin/CreatePost";
import Contact from "./pages/Contact/contact";
import About from "./pages/About/about";
import ManageTrips from "./pages/Admin/ManageTrips";
import UpdateTrip from "./pages/Admin/UpdateTrip";
import Details from "./pages/Details/Details";
import Guest from "./middleware/Guest";
import Admin from "./middleware/Admin";
import ViewBookings from "./pages/Admin/ViewBookings";
import TripsDetilse from "./pages/trips/Tripdetilse";
import Reviews from "./pages/Reviews/Reviews";
import Application from "./pages/trips/Application";
import Article from"./pages/About/article";
import ManageArticle from "./pages/Admin/ManageArticle";
import UpdateArticle from "./pages/Admin/UpdateArticle";
import CreateArticle from "./pages/Admin/CreateArticle";
import ArticleDetail from"./pages/About/ArticleDetail";



export const routes = createBrowserRouter([
    {
        path:"",
        element:<App/>,
        children: 
        [
            {
                path: "/",
                element: <Home/>,
            },
            {
                path: "/TripsCards",
                element: <TripsCards />, // Corrected route
            },
            {
                path: "/TripsDetails/:id",
                element: <TripsDetilse/>,
            },
            {
                path: "/Reviews",
                element: <Reviews limit={0}/>,
            },
            {
                path: "/Application",
                element: <Application/>,
            }, 



            {
                element:<Guest/>,
                children:[ {
                    path: "/Register",
                    element: <Register/>,
                },
                {
                    path: "/Login",
                    element: <Login/>,
                },]
            },
            {
                element:<Admin/>,
                children:[ 

                    {
                        path: "/post",
                        element: <CreatePost/>,
                    },
                    {path:"/CreateArticle",
                        element: <CreateArticle/>,

                    },
                    {
                        path: "/managebookings",
                        element: <ViewBookings/>,
                    },
                    {
                        path: "/managetrips",
                        children:[
                            {
                            path:"",                
                            element: <ManageTrips/>,
                            },
        
                            {
                                path: "update/:id",
                                element: <UpdateTrip/>,
                            },
                        ]
        
                    },
                    {
                        path: "/ManageArticle",
                        children:[
                            {
                            path:"",                
                            element: <ManageArticle/>,
                            },
        
                            {
                                path: "update/:id",
                                element: <UpdateArticle/>,
                            },
                        ]
        
                    },

                ]
            },
            {
                path: "/details",
                element: <Details/>,
            },

            {
                path: "/contact",
                element: <Contact/>,
            },
            {
                path: "/about",
                element: <About/>,
            },   
             {
                path: "/blog",
                element: <Article/>,
            },
            {
                path: "/ArticleDetail/:id",
                element: <ArticleDetail/>,

            },           
            
        ],
   
        
    },
    {
        path:"*",
        element:<Navigate to={"/"}/>,
    },

  ]);