import React, { useState, useEffect } from "react";
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import "../../Assets/css/spinner.css";
import { useNavigate, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
const UpdateArticle = () => {
    let { id: articleId } = useParams(); // Get articleId from URL
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState([]);
    const [article, setArticle] = useState({
        title: "",
        description: "",
        err: []
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (articleId) {
            const fetchArticleDetails = async () => {
                try {
                    const response = await axios.get(`https://api.goldenpharaohtours.com/getarticals/${articleId}`);
                    const article = response.data; // Access the data from response
    
                    setArticle({
                        title: article.title,
                        description: article.description, // Corrected typo
                        err: []
                    });
                    setPreview(article.images || []);
                } catch (error) {
                    console.error(error);
                    setArticle(prev => ({ ...prev, err: [error.response?.data?.msg || "Failed to fetch data"] }));
                }
            };
    
            fetchArticleDetails();
        }
    }, [articleId]);

    const handleUpdate = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("title", article.title);
        formData.append("descreption", article.descreption);

        for (let i = 0; i < images.length; i++) {
            formData.append("images", images[i]);
        }

        setLoading(true);
        try {
            const response = await axios.put(`https://api.goldenpharaohtours.com/updatearticals/${articleId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            navigate("/");
            setArticle({ ...article, loading: false, err: [] });
        } catch (error) {
            console.log(error);
            setArticle({ ...article, loading: false, err: [error.response?.data?.msg || "Update failed"] });
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        setImages(files);

        const imagesPreview = [];
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = () => {
                imagesPreview.push(reader.result);
                if (imagesPreview.length === files.length) {
                    setPreview(imagesPreview);
                }
            };
        }
    };

    const handleResetClick = () => {
        setPreview([]);
        setImages([]);
    };

    return (
        <div className="create-post-container">
            <Card className="create-post-card">
                <Card.Body>
                    <h1>Update Article</h1>
                    {article.err.map((error, index) => (
                        <Alert key={index} variant='danger'>
                            {error}
                        </Alert>
                    ))}
                    <Form onSubmit={handleUpdate} className="post-form">
                        <Form.Group className="article-title-group">
                            <Form.Label>Article Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={article.title}
                                onChange={(e) => setArticle({ ...article, title: e.target.value })}
                                placeholder="Enter article title"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Article Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={article.descreption}
                                onChange={(e) => setArticle({ ...article, descreption: e.target.value })}
                                rows={5}
                                placeholder="Write article description here..."
                                required
                            />
                        </Form.Group>

                        <div className="d-flex justify-content-between">
                            <Button variant="secondary" onClick={handleResetClick}>Reset Images</Button>
                            <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    Updating...
                                </>
                            ) : "Update Trip"}
                        </Button>

                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default UpdateArticle;
