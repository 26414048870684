import React, { useState } from "react";
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import "../../Assets/css/spinner.css";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill theme

const quillStyle = {
    height: '200px',
    marginBottom: '20px'
};

const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline'],
        [{ 'align': [] }],
        ['link', 'image']
    ]
};

const CreateArticle = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState([]);

    const navigate = useNavigate();

    const [article, setArticle] = useState({
        title: "",
        descreption: "",
        err: []
    });

    const Do_Post = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("title", article.title);
        formData.append("descreption", article.descreption);
        for (let i = 0; i < images.length; i++) {
            formData.append("images", images[i]);
        }

        setLoading(true);

        try {
            const response = await axios.post("https://goldenpharaohtours.com/createarticals", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate("/");
            setArticle({ ...article, loading: false, err: [] });
        } catch (error) {
            console.log(error);

            // Check if error.response and error.response.data exist before accessing data
            const errorMessage = error.response && error.response.data && error.response.data.msg
                ? error.response.data.msg
                : "Something went wrong. Please try again later.";

            setArticle({ ...article, loading: false, err: [errorMessage] });
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        setImages(files);

        const imagesPreview = [];
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = () => {
                imagesPreview.push(reader.result);
                if (imagesPreview.length === files.length) {
                    setPreview(imagesPreview);
                }
            };
        }
    };

    const handleResetClick = () => {
        setPreview([]);
        setImages([]);
    };

    return (
        <div className="create-post-container">
            <br></br><br></br><br></br>
            <Card className="create-post-card">
                <Card.Body>
                    <h1>Create Blog</h1>
                    {article.err.map((error, index) => (
                        <Alert key={index} variant='danger'>
                            {error}
                        </Alert>
                    ))}
                    <Form onSubmit={Do_Post} className="post-form">
                        <Form.Group className="article-title-group">
                            <Form.Label>Blog Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={article.title}
                                onChange={(e) => setArticle({ ...article, title: e.target.value })}
                                placeholder="Enter Blog title"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Blog Description</Form.Label>
                            <ReactQuill
                                value={article.descreption}
                                onChange={(value) => setArticle({ ...article, descreption: value })}
                                style={quillStyle}
                                modules={modules}
                                placeholder="Write blog description"
                                theme="snow"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Upload Blog Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleImageChange}
                                accept="image/*"
                                multiple
                            />
                            {preview && preview.length > 0 && preview.map((image, index) => (
                                <Image key={index} src={image} alt={`Preview ${index}`} thumbnail />
                            ))}
                        </Form.Group>

                        <div className="d-flex justify-content-between">
                            <Button variant="secondary" onClick={handleResetClick}>Reset Images</Button>
                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? "Creating..." : "Create Article"}
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default CreateArticle;
