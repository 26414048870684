import React, { useState, useEffect } from "react";
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import 'react-clock/dist/Clock.css';
import "../../Assets/css/spinner.css";
import { getAuthUser } from '../../Helper/Storage';
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import './CreatePost.css';
import Spinner from 'react-bootstrap/Spinner';

const Auth = getAuthUser();

const CreatePost = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [selectedDestination, setSelectedDestination] = useState("Select Destination");
    const [tripInclusions, setTripInclusions] = useState([""]);
    const [tripExclusions, setTripExclusions] = useState([""]);
    const [offerName, setOfferName] = useState("");
    const [offerDescription, setOfferDescription] = useState("");
    const [itinerary, setItinerary] = useState([{ day: "", description: "" }]);
    const [destinations, setDestinations] = useState([]);
    const [type, setType] = useState("Adventure"); // Default selection for type
    const navigate = useNavigate();

    const [Post, setPost] = useState({
        name: "",
        description: "",
        salarySingle: "",
        salaryDouble: "",
        salaryGroup: "",
        category: "",
        destination: "",
        duration: "",
        country: "",
        other: "none",
        err: []
    });

    useEffect(() => {
        const fetchDestinations = async () => {
            try {
                const response = await axios.get('https://api.goldenpharaohtours.com/getdestinations');
                setDestinations(response.data);
            } catch (error) {
                console.error("Failed to fetch destinations", error);
            }
        };

        fetchDestinations();
    }, []);

    const Do_Post = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", Post.name);
        formData.append("description", Post.description);
        formData.append("price1", Post.salarySingle);
        formData.append("price2", Post.salaryDouble);
        formData.append("pricee", Post.salaryGroup);
        formData.append("category", selectedCategory);
        formData.append("destination", selectedDestination);
        formData.append("included", tripInclusions.join(","));
        formData.append("excluded", tripExclusions.join(","));
        formData.append("offerName", offerName);
        formData.append("offerDes", offerDescription);
        formData.append("Duration", Post.duration);
        formData.append("Countries", Post.country);
        formData.append("Type", type);
        formData.append("Run", "Weekly");
        formData.append("Itinerary", JSON.stringify(itinerary));

        for (let i = 0; i < images.length; i++) {
            formData.append("images", images[i]);
        }

        setLoading(true);
        {/*for (let pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }
            */}
        try {
            const response = await axios.post("https://api.goldenpharaohtours.com/CreateTrip", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            navigate("/");
            setPost({ ...Post, loading: false, err: [] });
        } catch (error) {
            console.log(error);
            setPost({ ...Post, loading: false, err: [error.response.data.msg] });
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        setImages(files);

        const imagesPreview = [];
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = () => {
                imagesPreview.push(reader.result);
                if (imagesPreview.length === files.length) {
                    setPreview(imagesPreview);
                }
            };
        }
    };

    const handleResetClick = () => {
        setPreview(null);
        setImages([]);
    };

    const handleInclusionsChange = (index, value) => {
        const newInclusions = [...tripInclusions];
        newInclusions[index] = value;
        setTripInclusions(newInclusions);
    };

    const addInclusion = () => {
        setTripInclusions([...tripInclusions, ""]);
    };

    const removeInclusion = (index) => {
        const newInclusions = tripInclusions.filter((_, i) => i !== index);
        setTripInclusions(newInclusions);
    };

    const handleExclusionsChange = (index, value) => {
        const newExclusions = [...tripExclusions];
        newExclusions[index] = value;
        setTripExclusions(newExclusions);
    };

    const addExclusion = () => {
        setTripExclusions([...tripExclusions, ""]);
    };

    const removeExclusion = (index) => {
        const newExclusions = tripExclusions.filter((_, i) => i !== index);
        setTripExclusions(newExclusions);
    };

    const handleItineraryChange = (index, field, value) => {
        const newItinerary = [...itinerary];
        newItinerary[index][field] = value;
        setItinerary(newItinerary);
    };

    const addItineraryDay = () => {
        setItinerary([...itinerary, { day: "", description: "" }]);
    };

    const removeItineraryDay = (index) => {
        const newItinerary = itinerary.filter((_, i) => i !== index);
        setItinerary(newItinerary);
    };

    return (
        <div className="create-post-container">
            <Card className="create-post-card">
                <Card.Body>
                    <br></br><br></br>
                    <h1>New Trip</h1>
                    {Post.err.map((error, index) => (
                        <Alert key={index} variant='danger'>
                            {error}
                        </Alert>
                    ))}
                    <Form onSubmit={Do_Post} className="post-form">
                        <Form.Group className="trip-destination-group">
                            <Form.Label>Trip Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={Post.name}
                                onChange={(e) => setPost({ ...Post, name: e.target.value })}
                                placeholder="Enter trip destination"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Trip Details</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={Post.description}
                                onChange={(e) => setPost({ ...Post, description: e.target.value })}
                                rows={5}
                                placeholder="Write trip details here..."
                                required
                            />
                        </Form.Group>

                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Category</Form.Label>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            {selectedCategory}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setSelectedCategory("All")}>All</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("FullDay")}>Full Day</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("HalfDay")}>Half Day</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("Nighttours")}>Night tours</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("Packages")}>Packages</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("SpecialOffers")}>Special Offers</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSelectedCategory("VIP")}>VIP</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Destination</Form.Label>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            {selectedDestination}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {destinations.map((destination) => (
                                                <Dropdown.Item
                                                    key={destination.id}
                                                    onClick={() => setSelectedDestination(destination.name)}
                                                >
                                                    {destination.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Label>Offer Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={offerName}
                                onChange={(e) => setOfferName(e.target.value)}
                                placeholder="Enter offer name"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Offer Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={offerDescription}
                                onChange={(e) => setOfferDescription(e.target.value)}
                                rows={3}
                                placeholder="Write offer description..."
                                required
                            />
                        </Form.Group>

                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Duration</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={Post.duration}
                                        onChange={(e) => setPost({ ...Post, duration: e.target.value })}
                                        placeholder="Enter duration"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={Post.country}
                                        onChange={(e) => setPost({ ...Post, country: e.target.value })}
                                        placeholder="Enter country"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Label>Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                required
                            >
                                <option value="Adventure">Adventure</option>
                                <option value="Historical">Historical</option>
                                <option value="Culture">Culture</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Salary (Single)</Form.Label>
                            <Form.Control
                                type="number"
                                value={Post.salarySingle}
                                onChange={(e) => setPost({ ...Post, salarySingle: e.target.value })}
                                placeholder="Enter single salary"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Salary (Double)</Form.Label>
                            <Form.Control
                                type="number"
                                value={Post.salaryDouble}
                                onChange={(e) => setPost({ ...Post, salaryDouble: e.target.value })}
                                placeholder="Enter double salary"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Salary (Group)</Form.Label>
                            <Form.Control
                               type="number"
                                value={Post.salaryGroup}
                                onChange={(e) => setPost({ ...Post, salaryGroup: e.target.value })}
                                placeholder="Enter group salary"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Trip Inclusions</Form.Label>
                            {tripInclusions.map((inclusion, index) => (
                                <div key={index} className="inclusion-item">
                                    <Form.Control
                                        type="text"
                                        value={inclusion}
                                        onChange={(e) => handleInclusionsChange(index, e.target.value)}
                                        placeholder={`Inclusion ${index + 1}`}
                                        required
                                    />
                                    <Button variant="danger" onClick={() => removeInclusion(index)}>-</Button>
                                </div>
                            ))}
                            <Button variant="primary" onClick={addInclusion}>+ Add Inclusion</Button>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Trip Exclusions</Form.Label>
                            {tripExclusions.map((exclusion, index) => (
                                <div key={index} className="exclusion-item">
                                    <Form.Control
                                        type="text"
                                        value={exclusion}
                                        onChange={(e) => handleExclusionsChange(index, e.target.value)}
                                        placeholder={`Exclusion ${index + 1}`}
                                        required
                                    />
                                    <Button variant="danger" onClick={() => removeExclusion(index)}>-</Button>
                                </div>
                            ))}
                            <Button variant="primary" onClick={addExclusion}>+ Add Exclusion</Button>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Itinerary</Form.Label>
                            {itinerary.map((day, index) => (
                                <div key={index} className="itinerary-day">
                                    <Form.Control
                                        type="text"
                                        value={day.day}
                                        onChange={(e) => handleItineraryChange(index, "day", e.target.value)}
                                        placeholder={`Day ${index + 1}`}
                                        required
                                    />
                                    <Form.Control
                                        as="textarea"
                                        value={day.description}
                                        onChange={(e) => handleItineraryChange(index, "description", e.target.value)}
                                        rows={3}
                                        placeholder={`Description for Day ${index + 1}`}
                                        required
                                    />
                                    <Button variant="danger" onClick={() => removeItineraryDay(index)}>-</Button>
                                </div>
                            ))}
                            <Button variant="primary" onClick={addItineraryDay}>+ Add Day</Button>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Upload Images</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleImageChange}
                                multiple
                                required
                            />
                            {preview && (
                                <div className="image-preview">
                                    {preview.map((src, index) => (
                                        <Image key={index} src={src} thumbnail />
                                    ))}
                                </div>
                            )}
                            <br></br>
                            <Button variant="danger" onClick={handleResetClick}>Reset Images</Button>
                        </Form.Group>



                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    Creating Post......
                                </>
                            ) : "Create Post"}
                        </Button>

                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default CreatePost;
